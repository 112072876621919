import React from 'react';

import Layout from '../components/Layout';
import placeholder from '../assets/images/bg-white.jpg';

import anyoccasion1 from '../assets/images/anyoccasion-01.jpg';
import anyoccasion2 from '../assets/images/anyoccasion-02.jpg';
import anyoccasion3 from '../assets/images/anyoccasion-03.jpg';
import anyoccasion4 from '../assets/images/anyoccasion-04.jpg';
import anyoccasion5 from '../assets/images/anyoccasion-05.jpg';
import anyoccasion6 from '../assets/images/anyoccasion-06.jpg';
import anyoccasion7 from '../assets/images/anyoccasion-07.jpg';
import anyoccasion8 from '../assets/images/anyoccasion-08.jpg';
import anyoccasion9 from '../assets/images/anyoccasion-09.jpg';
import anyoccasion10 from '../assets/images/anyoccasion-10.jpg';
import anyoccasion11 from '../assets/images/anyoccasion-11.jpg';
import anyoccasion12 from '../assets/images/anyoccasion-12.jpg';
import anyoccasion13 from '../assets/images/anyoccasion-13.jpg';
import anyoccasion14 from '../assets/images/anyoccasion-14.jpg';
import anyoccasion15 from '../assets/images/anyoccasion-15.jpg';
import anyoccasion16 from '../assets/images/anyoccasion-16.jpg';
import anyoccasion17 from '../assets/images/anyoccasion-17.jpg';
import anyoccasion18 from '../assets/images/anyoccasion-18.jpg';
import anyoccasion19 from '../assets/images/anyoccasion-19.jpg';
import anyoccasion20 from '../assets/images/anyoccasion-20.jpg';
import anyoccasion21 from '../assets/images/anyoccasion-21.jpg';
import anyoccasion22 from '../assets/images/anyoccasion-22.jpg';




const IndexPage = () => (
  <Layout activeLink="Any Occasion">
    
    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Any Occasion 1 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion1}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Flourless Almond Cake</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Any Occasion 2 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img2 mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion2}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Coconut Mango Panna Cotta</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

          {/* Any Occasion 3 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img2 mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion3}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Orange Chiffon with Orange Glaze</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Any Occasion 4 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img2 mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion4}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Jaffa Cake Slice</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Any Occasion 5 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion5}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Orange Chiffon Slice</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Any Occasion 6 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion6}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Chocolate Brownie with Chocolate Ganache</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Any Occasion 7 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion7}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Dessert</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Any Occasion 8 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion8}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Custard Fruit Tart</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Any Occasion 9 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion9}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Red Velvet Cake with Cream Cheese</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
    
        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Any Occasion 10 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion10}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Chocolate Ripple Cake with Cherry</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Any Occasion 11 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion11}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Coffee Cake with Caramel & Roasted Almonds</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Any Occasion 12 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion12}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Coconut Pudding</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Any Occasion 13 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion13}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Chocolate Mousse Cake</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Any Occasion 14 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion14}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Fresh Coconut Cake</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Any Occasion 15 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion15}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Orange Sponge Cake with Homemade Orange Sauce</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Any Occasion 16 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion16}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Flourless Orange & Almond Cake</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Any Occasion 17 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion17}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Coconut Cake</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

          {/* Any Occasion 18 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion18}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Vanilla Strawberry Panna Cotta</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
        {/* Any Occasion 19 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion19}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Flourless Orange and Almond Cake</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Any Occasion 20 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion20}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Fresh Fruit Cake</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

          {/* Any Occasion 21 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img2 mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion21}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Coffee Caramel Panna Cotta</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">

          {/* Any Occasion 22 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img2 mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={anyoccasion22}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Raspberry Cheese Cake</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Dessert</h6>
              <p class="card-text">Awesome Dessert.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

          {/* Placeholder */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={placeholder}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title"> </h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              {/* <a href="/contact/" className="card-link">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

          {/* Placeholder */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={placeholder}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title"> </h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              {/* <a href="/contact/" className="card-link">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 
 

    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="text-center rounded">
              <section>
                <header>
                  {/* <h3>Table</h3> */}
                </header>
                <div className="table-wrapper">
                  <table className="default">
                    <thead>
                      <tr>
                        <th><h1><a href="https://www.facebook.com/Designer-sweet-treats-144017559724157/?tn-str=k*F" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square social"></i></a></h1></th>
                        <th><h1><a href="https://www.instagram.com/designer_sweet_treats/?hl=en" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram social"></i></a></h1></th>
                        <th><h1><a href="mailto:jeeranan@designersweettreats.net?subject=New%20Enquiry&body=Please%20tell%20us%20the%20following%20information%3A%0D%0A%0D%0AName%3A%20%0D%0AEvent%20Date%3A%20%0D%0AFlavours%3A%0D%0ANumber%20of%20Servings%3A%0D%0ACake%20Design%3A%0D%0AContact%20Number%3A%0D%0A%0D%0AIf%20possible%20please%20also%20attach%20a%20sketch%20or%20photo%20of%20the%20cake%20you%20would%20like." target="_top"><i class="far fa-envelope social"></i></a></h1></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
